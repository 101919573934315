import React from 'react';
import { useGetDronesQuery } from '@/features/api/copApiEndpoints/droneApi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAppSelector } from '@/app/hooks';
import styles from './DroneTab.module.scss';

export default function DroneTab() {
  const { data: drones, isLoading } = useGetDronesQuery('');
  const hubState = useAppSelector((state) => state.hub);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <TableContainer 
      component={Paper} 
      style={{
        backgroundColor: styles.tabBgColor, 
        border: styles.tabBorder, 
        borderRadius: styles.tabBorderRadius
      }}
    >
      <Table aria-label="drone table">
        <TableHead>
          <TableRow style={{borderBottom: 'unset'}}> 
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Reference</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Call Sign</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Model</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Status</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Current Hub</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Batteries</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(drones) && drones.map((drone) => (
            <TableRow key={drone.reference}>
              <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>{drone.reference}</TableCell>
              <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>{drone.callSign}</TableCell>
              <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>{drone.model}</TableCell>
              <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>{drone.loaded ? 'Loaded' : 'Empty'}</TableCell>
              <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>
                {hubState.hubList.find(hub => hub.id === drone.currentHubId)?.name || 'N/A'}
              </TableCell>
              <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>{drone.currentBatteries}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
